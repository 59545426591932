import React from "react";
import HeroSection4 from "./../components/HeroSection4";
import ContentCardsSection from "./../components/ContentCardsSection";

function BlogPage(props) {
  return (
    <>
      <HeroSection4
        bg="white"
        textColor="dark"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="Blog Posts and Articles"
        subtitle=""
      />
      <ContentCardsSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Featured Content"
        subtitle=""
      />
    </>
  );
}

export default BlogPage;
