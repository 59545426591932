import React from "react";
import PricingSection from "./../components/PricingSection";
import FaqSection from "./../components/BillingFaqSection";

function PlansPage(props) {
  return (
    <>
      <PricingSection
        bg="white"
        textColor="dark"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="Our Subscription Plans"
        subtitle="Choose the plan that makes sense for you. All plans include a 14-day free trial."
      />
      <FaqSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Plan & Billing FAQs"
        subtitle=""
      />
    </>
  );
}

export default PlansPage;
