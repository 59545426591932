import React from "react";
import PostSection from "./../../components/PostSection";

function PostCyberblitzPage(props) {
  return (
    <PostSection
      bg="white"
      textColor="dark"
      size="lg"
      bgImage=""
      bgImageOpacity={1}
      title="Introducing Cyberblitz.it"
      subtitle="by Steve R. Smith, CEO of Consumer Affinity"
    />
  );
}

export default PostCyberblitzPage;
