import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function StatsSection(props) {
  const items = [
    {
      title: "Servers",
      stat: "231M",
    },
    {
      title: "Web Servers",
      stat: "347k",
    },
    {
      title: "Email Servers",
      stat: "416k",
    },
    {
      title: "DNS Servers",
      stat: "197k",
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <h2
          className={
            "font-weight-bold" +
            (props.subtitle && props.spaced ? " mb-4" : "") +
            (!props.subtitle ? " mb-0" : "") +
            (props.size ? ` h${props.size}` : "")
          }
        >
          Internet Statistics
        </h2>
        <Row className="justify-content-center">
          {items.map((item, index) => (
            <Col xs={12} md={3} className="py-3 px-4" key={index}>
              <div>
                <small className="text-uppercase">{item.title}</small>
                <h2 className="mt-2">{item.stat}</h2>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default StatsSection;
