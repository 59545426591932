import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "./../util/router.js";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";

function SubscribeToAPlan(props) {
  const items = [
    {
      title: "Chat",
      description:
        "Imagine a world where you can chat with your friends without having to see their dumb faces.",
      iconClass: "fas fa-comments",
      iconColor: "primary",
    },
    {
      title: "Play",
      description:
        "We embedded a bunch free flash games inside our app. What we lack in quality we make up in quantity.",
      iconClass: "fas fa-gamepad",
      iconColor: "danger",
    },
    {
      title: "Cats",
      description:
        "Our market research told us people like cats so we put some cat icons on our website.",
      iconClass: "fas fa-cat",
      iconColor: "dark",
    },
  ];

  return (
    <div>
      <Section
        bg={props.bg}
        textColor={props.textColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container>
          <Row className="align-items-center">
            <Container>
              <Col lg={15} className="text-center text-lg-left">
                <div>
                  We're excited to have you as a customer but Cyberblitz is only
                  available with one of our paid subscriptions. Please subscribe
                  to one of our three plans to use Cyberblitz. We do offer a 14
                  day free trial with all three plans.
                </div>
                <div>
                  <br />
                  <Link to="/plans">
                    <LinkContainer to={props.buttonPath}>
                      <Button variant={props.buttonColor} size="lg">
                        {props.buttonText}
                      </Button>
                    </LinkContainer>
                  </Link>
                </div>
              </Col>
            </Container>
          </Row>
        </Container>
      </Section>
    </div>
  );
}

export default SubscribeToAPlan;
