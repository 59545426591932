import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import SubscribeToAPlan from "./SubscribeToAPlan";

function HeroSection7(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={1}
          spaced={true}
          className="text-center"
        />
        <SubscribeToAPlan
          bg="white"
          textColor="dark"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          image="https://uploads.divjoy.com/undraw-chatting_2yvo.svg"
          buttonText="Subscribe to a Plan"
          buttonColor="primary"
          buttonPath="/plans"
        />
      </Container>
    </Section>
  );
}

export default HeroSection7;
