import React from "react";
import Container from "react-bootstrap/Container";
import HeroSectionProductDashboard from "./../../components/HeroSectionProductDashboard";
import ProductSection from "./../../components/ProductSection";
import HeroSection7 from "./../../components/HeroSection7";
import { useAuth, requireAuth } from "./../../util/auth.js";
import "./dashboard.scss";

function ProductDashboardPage(props) {
  const auth = useAuth();

  return (
    <>
      {auth.user.stripeSubscriptionId && (
        <Container>
          <div className="ProductDashboardPage__no-classname">
            <br />
            <br />
            <br />
            <br />
            <HeroSectionProductDashboard
              bg="white"
              textColor="dark"
              size="sm"
              bgImage=""
              bgImageOpacity={1}
              title=""/>
 			        <div class="row justify-content-center">
                  <div class="col-12 col-md-10 col-lg-10">
                    <form class="card card-sm">
                      <div class="card-body row no-gutters align-items-center">
                        <div class="col-auto">
                          <i class="fa fa-search h4 text-body"></i>
                        </div>
                        <div class="col d-inline-flex p-2">
                          <input class="form-control form-control-lg form-control-borderless" type="search" placeholder="Search for Company Records" />
                        </div>
                        <div class="col-auto">
                          <button class="btn btn-lg btn-primary" type="submit">Search</button>
                        </div>
                      </div>
                    </form>
                  </div>
              </div> 
            <div>
              <ProductSection
                bg="white"
                textColor="dark"
                size="sm"
                bgImage=""
                bgImageOpacity={1}
                image="https://uploads.divjoy.com/undraw-chatting_2yvo.svg"
              />
            </div>
          </div>
        </Container>
      )}

      {!auth.user.stripeSubscriptionId && (
        <HeroSection7
          bg="white"
          textColor="dark"
          size="lg"
          bgImage=""
          bgImageOpacity={1}
          title="Subscription Required"
          subtitle=""
        />
      )}
    </>
  );
}

export default requireAuth(ProductDashboardPage);
