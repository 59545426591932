import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function DashSection2(props) {
  const items = [
    {
      title: "Domains",
      subtitle: "83",
      iconClass: "fas fa-cube",
      iconColor: "primary",
    },
    {
      title: "Netblocks",
      subtitle: "10",
      iconClass: "fas fa-network-wired",
      iconColor: "primary",
    },
    {
      title: "Names Resolved",
      subtitle: "13",
      iconClass: "fas fa-file-signature",
      iconColor: "primary",
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <Row className="justify-content-center">
          {items.map((item, index) => (
            <Col xs={12} lg={4} className="px-4" key={index}>
              <div className="py-3">
                <div className={`text-${item.iconColor} display-4 mb-4`}>
                  <i className={`${item.iconClass}`} />
                </div>
                <h4 className="mb-4">{item.title}</h4>
                <p>{item.subtitle}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default DashSection2;
