import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader2 from "./SectionHeader2";
import ContentCardsSection2 from "./ContentCardsSection2";

function PostSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader2
          title={props.title}
          subtitle={props.subtitle}
          size={1}
          spaced={true}
          className="text-center"
        />
        <ContentCardsSection2
          bg="white"
          textColor="dark"
          size="sm"
          bgImage=""
          bgImageOpacity={1}
          title=""
          subtitle=""
        />
      </Container>
    </Section>
  );
}

export default PostSection;
