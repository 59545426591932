import React from "react";
import HeroSection3 from "./../components/HeroSection3";
import FaqSection from "./../components/FaqSection";
import CtaSection from "./../components/CtaSection";

function ResourcesPage(props) {
  return (
    <>
      <HeroSection3
        bg="white"
        textColor="dark"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="Frequently Asked Questions"
        subtitle=""
      />
      <FaqSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Feel free to contact us for help: support@consumeraffinity.com"
        subtitle=""
      />
      <CtaSection
        bg="primary"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="light"
        buttonPath="/plans"
      />
    </>
  );
}

export default ResourcesPage;
