import React from "react";
import Section3 from "./Section3";
import Container from "react-bootstrap/Container";
import SectionHeader4 from "./SectionHeader4";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function HowProcessSection(props) {
  const items = [
    {
      title: "Reverse and Forward Whois",
      subtitle:
        "Domains are enumerated using company names or registrant email addresses",
      iconClass: "fas fa-window-restore",
      iconColor: "primary",
    },
    {
      title: "DNS Lookups",
      subtitle:
        "14 record types for each domain are enumerated using nslookup.",
      iconClass: "fas fa-map-marked-alt",
      iconColor: "primary",
    },
    {
      title: "IP Addresses",
      subtitle:
        "ARIN, domains, and DNS records identify potential assets.",
      iconClass: "fas fa-server",
      iconColor: "primary",
    },
    {
      title: "Ports and Banners",
      subtitle:
        "Shodan.io and other resources find open ports and services.",
      iconClass: "fas fa-server",
      iconColor: "primary",
    },
    {
      title: "TLS and SSL Certificates",
      subtitle: "Mozilla Observatory helps assess certs.",
      iconClass: "fas fa-certificate",
      iconColor: "primary",
    },
  ];

  return (
    <Section3
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <SectionHeader4
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
        />
        <Row className="justify-content-center">
          {items.map((item, index) => (
            <Col xs={12} lg={4} className="px-4" key={index}>
              <div className="py-3">
                <div className={`text-${item.iconColor} display-4 mb-4`}>
                  <i className={`${item.iconClass}`} />
                </div>
                <h4 className="mb-4">{item.title}</h4>
                <p>{item.subtitle}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Section3>
  );
}

export default HowProcessSection;
