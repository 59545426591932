import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AspectRatio from "./AspectRatio";
import Image from "react-bootstrap/Image";
import "./CompanyInformation.scss";

function CompanyInformation(props) {
  const items = [
    {
      title: "Company Profile",
      body: "Basic details like legal name, industries, and founded date.",
      image:
        "https://consumeraffinity.com/wp-content/uploads/2021/01/company_profile.svg",
    },
    {
      title: "Physical Presence",
      body: "Locations, headquarters, divisions, and subsidiaries.",
      image:
        "https://consumeraffinity.com/wp-content/uploads/2021/01/physical_presence.svg",
    },
    {
      title: "Employee Information",
      body: "Number of employees, founders, executive team members.",
      image:
        "https://consumeraffinity.com/wp-content/uploads/2021/01/employee_information.svg",
    },
    {
      title: "Financial Information",
      body: "Number of acquisitions, funding amount, number of investors.",
      image:
        "https://consumeraffinity.com/wp-content/uploads/2021/01/financial_information.svg",
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
        />
        <Card>
          <Row className="no-gutters overflow-hidden">
            {items.map((item, index) => (
              <Col
                xs={12}
                lg={6}
                style={{
                  display: "flex",
                  alignItems: "stretch",
                  justifyContent: "center",
                  boxShadow: "1px 1px 0 0 #efefef",
                }}
                key={index}
              >
                <div className="CompanyInformation__item has-text-centered">
                  <div className="CompanyInformation__image-container">
                    <AspectRatio ratio={4 / 3}>
                      <Image src={item.image} alt={item.title} fluid={true} />
                    </AspectRatio>
                  </div>
                  <h4>{item.title}</h4>
                  <p>{item.body}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Card>
      </Container>
    </Section>
  );
}

export default CompanyInformation;
