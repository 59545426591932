import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function FeaturesSection(props) {
  const items = [
    {
      title: "APIs",
      subtitle:
        "We consume information using pubicly available websites and paid commercial APIs.",
      iconClass: "fas fa-code",
      iconColor: "primary",
    },
    {
      title: "Commands",
      subtitle:
        "We use common tactics and command line tools to obtain information about IT assets.",
      iconClass: "fas fa-terminal",
      iconColor: "primary",
    },
    {
      title: "Crawlers",
      subtitle:
        "Custom crawlers that scrape information from publicly accessible websites.",
      iconClass: "fas fa-robot",
      iconColor: "primary",
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <Row className="justify-content-center">
          {items.map((item, index) => (
            <Col xs={12} lg={4} className="px-4" key={index}>
              <div className="py-3">
                <div className={`text-${item.iconColor} display-4 mb-4`}>
                  <i className={`${item.iconClass}`} />
                </div>
                <h4 className="mb-4">{item.title}</h4>
                <p>{item.subtitle}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
