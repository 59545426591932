import React, { useState } from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import "./FaqSection.scss";

function FaqSection(props) {
  // Object to store expanded state for all items
  const [expanded, setExpanded] = useState({});
  // Set an item's expanded state
  const setExpandedItem = (index, isExpanded) => {
    setExpanded({
      ...expanded,
      [index]: isExpanded,
    });
  };

  const items = [
    {
      question:
        "How does Cyberblitz.it know from which sites to gather information?",
      answer:
        "We have been in the information security field for 20+ years. Our experience includes penetration testing and corporate information security. We use techniques similar to what adversaries use profile your company to  understand your Internet based IT environment. That is just the first step.",
    },
    {
      question: "How many websites do you use to gather information?",
      answer:
        "We pull data from a number of authoritative sources that provide the consistency and reliability we need to provide to our customers. We continue to look for additional data to ingest to enhance our feeds.",
    },
    {
      question: "What commands do you use?",
      answer:
        "We use a combination of basic networking tools and commands available to anyone that uses the UNIX operating system.",
    },
    {
      question: "Are these commands dangerous?",
      answer:
        "No, they are not at all dangerous. We do not communicate directly with any of your IT assets but instead query other services that know information about them.",
    },
    {
      question: "Can anyone detect what you are doing?",
      answer:
        "At the moment, we use non-invasive techniques that cannot be detected by monitoring and detection tools.",
    },
    {
      question: "How accurate is the information you gather?",
      answer:
        "We use reliable sources of information and correlate what we find with other pieces when we have them.",
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />

        {items.map((item, index) => (
          <article
            className="FaqSection__faq-item py-4"
            onClick={() => {
              setExpandedItem(index, !expanded[index]);
            }}
            key={index}
          >
            <h4>
              <span className="text-primary mr-3">
                <i
                  className={
                    "fas" +
                    (expanded[index] ? " fa-minus" : "") +
                    (!expanded[index] ? " fa-plus" : "")
                  }
                />
              </span>
              {item.question}
            </h4>

            {expanded[index] && <div className="mt-3">{item.answer}</div>}
          </article>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
