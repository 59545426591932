import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import AspectRatio from "./AspectRatio";

function TeamBiosSection(props) {
  const items = [
    {
      image:
        "https://consumeraffinity.com/wp-content/uploads/2021/01/Steve_transparent.png",
      name: "Steve R. Smith",
      role: "CEO and Founder",
    },
    {
      image:
        "https://consumeraffinity.com/wp-content/uploads/2021/01/Chris_transparent.png",
      name: "Chris Clark",
      role: "Security Engineer",
    },
    {
      image:
        "https://consumeraffinity.com/wp-content/uploads/2021/01/Josh_transparent.png",
      name: "Josh Evard",
      role: "Customer Success",
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <Row className="justify-content-center">
          {items.map((item, index) => (
            <Col
              xs={12}
              md={6}
              lg={4}
              className="py-3 d-flex align-items-stretch"
              key={index}
            >
              <Card className="flex-grow-1">
                <AspectRatio ratio={1 / 1.3}>
                  <Card.Img src={item.image} alt={item.title} variant="top" />
                </AspectRatio>
                <Card.Body className="d-flex flex-column text-center align-items-center p-4">
                  <h6 className="font-weight-bold mb-0">{item.name}</h6>
                  <small>{item.role}</small>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default TeamBiosSection;
