import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function ClientsSection(props) {
  const items = [
    {
      name: "Placeholder",
      image:
        "https://consumeraffinity.com/wp-content/uploads/2021/01/networking.svg",
      width: "500px",
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
        />
        <Row className="justify-content-center">
          {items.map((item, index) => (
            <Col md="auto" className="py-3 px-4" key={index}>
              <div className="align-bottom">
                <img src={item.image} width={item.width} alt={item.name} />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default ClientsSection;
