import React from "react";
import HeroSection2 from "./../components/HeroSection2";
import ItInformation from "./../components/ItInformation";
import CompanyInformation from "./../components/CompanyInformation";
import ClientsSection from "./../components/ClientsSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
import CtaSection from "./../components/CtaSection";

function IndexPage(props) {
  return (
    <>
      <HeroSection2
        bg="white"
        textColor="dark"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="Automated Attack Surface Analysis"
        subtitle="Continuously profile your Internet assets and conduct attack surface analysis without spending weeks building Excel spreadsheets."
        image="https://uploads.divjoy.com/undraw-japan_ubgk.svg"
        buttonText="Start the Analysis"
        buttonColor="primary"
        buttonPath="/auth/signup"
      />
      <ItInformation
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Examples of IT Data Gathered"
        subtitle="You can't assess blind spots. Simply enter your company name and Cyberblitz.it discovers domain names, IP addresses, ports, banners, and SSL/TLS certs."
      />
      <CompanyInformation
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Examples of Company Information Gathered"
        subtitle="Cyberblitz gathers information that could be used in social engineering, phishing, and other technical attacks."
      />
      <ClientsSection
        bg="light"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Many companies struggle to maintain accurate cyber asset inventories"
        subtitle="There are 230,000,000+ servers and 347,000+ web servers on the Internet."
      />
      <TestimonialsSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      />
      <NewsletterSection
        bg="light"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
      <CtaSection
        bg="primary"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="light"
        buttonPath="/auth/signup"
      />
    </>
  );
}

export default IndexPage;
