import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AspectRatio from "./AspectRatio";
import Image from "react-bootstrap/Image";
import "./ItInformation.scss";

function ItInformation(props) {
  const items = [
    {
      title: "Domains & Name Resolution",
      body: "Domain names registered to your company.",
      image:
        "https://consumeraffinity.com/wp-content/uploads/2021/01/domain_names.svg",
    },
    {
      title: "Netblocks & IP Addresses",
      body: "All of the Netblocks or IP addresses that Cyberblitz.it can find.",
      image:
        "https://consumeraffinity.com/wp-content/uploads/2021/01/networking.svg",
    },
    {
      title: "IT Assets",
      body: "Internet connected assets and resources.",
      image:
        "https://consumeraffinity.com/wp-content/uploads/2021/01/researching.svg",
    },
    {
      title: "IT Asset Details",
      body: "Open ports, services, banners, and TLS/SSL certs.",
      image:
        "https://consumeraffinity.com/wp-content/uploads/2021/01/assets.svg",
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
        />
        <Card>
          <Row className="no-gutters overflow-hidden">
            {items.map((item, index) => (
              <Col
                xs={12}
                lg={6}
                style={{
                  display: "flex",
                  alignItems: "stretch",
                  justifyContent: "center",
                  boxShadow: "1px 1px 0 0 #efefef",
                }}
                key={index}
              >
                <div className="ItInformation__item has-text-centered">
                  <div className="ItInformation__image-container">
                    <AspectRatio ratio={4 / 3}>
                      <Image src={item.image} alt={item.title} fluid={true} />
                    </AspectRatio>
                  </div>
                  <h4>{item.title}</h4>
                  <p>{item.body}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Card>
      </Container>
    </Section>
  );
}

export default ItInformation;
