import React from "react";
import HeroSection5 from "./../components/HeroSection5";
import FeaturesSection from "./../components/FeaturesSection";
import HowProcessSection from "./../components/HowProcessSection";
import VideoSection from "./../components/VideoSection";
import TestimonialsSection2 from "./../components/TestimonialsSection2";
import ContentCardsSection from "./../components/ContentCardsSection";

function HowItWorksPage(props) {
  return (
    <>
      <HeroSection5
        bg="white"
        textColor="dark"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="Quickly discover what people can find out about your company by scouring public repositories"
        subtitle="By the time you finish analyzing domain names, netblocks, ports, and banners, the data is already stale! Use Cyberblitz.it's continuous attack surface analysis do the work for you." 
      />
      <FeaturesSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
      />
      <HowProcessSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Is Cyberblitz.it Magic?"
        subtitle="Cyberblitz.it continuously connects to resources and enumerates information just like we do manually, saving precious time."
      />
      <VideoSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="See How Cyberblitz.it Works"
        subtitle=""
        embedUrl="https://www.youtube.com/embed/FAT400K6QJc?controls=0/"
      />
      <TestimonialsSection2
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="What People are Saying About Cyberblitz.it"
        subtitle=""
      />
      <ContentCardsSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Our Latest Articles and Blog Posts"
        subtitle=""
      />
    </>
  );
}

export default HowItWorksPage;
