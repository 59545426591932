import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Avatar from "./Avatar";

function TestimonialsSection2(props) {
  const items = [
    {
      avatar:
        "https://consumeraffinity.com/wp-content/uploads/2021/02/1552088731198.jpg",
      name: "Phillip Bosco",
      testimonial:
        "Cyberblitz sounds great! I believe so much of what you want to accomplish would be really useful for many teams and groups.",
      company: "Security Illusion",
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <Row className="justify-content-center">
          {items.map((item, index) => (
            <Col xs={12} md={4} className="py-3" key={index}>
              <Card>
                <Card.Body className="p-4 text-center">
                  <Avatar src={item.avatar} alt={item.name} size="96px" />
                  <Card.Text className="mt-4">"{item.testimonial}"</Card.Text>
                  <h6 className="font-weight-bold mb-0 mt-4">{item.name}</h6>
                  <small>{item.company}</small>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default TestimonialsSection2;
