import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import { useAuth } from "./../util/auth.js";

function NavbarCustom(props) {
  const auth = useAuth();

  return (
    <Navbar
      bg={props.bg}
      variant={props.variant}
      expand={props.expand}
      sticky="top"
    >
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img
              className="d-inline-block align-top"
              src={props.logo}
              alt="Cyberblitz.it"
              height="75"
            />
          </Navbar.Brand>
        </LinkContainer>

        <Navbar.Toggle aria-controls="navbar-nav" className="border-0" />
        <Navbar.Collapse id="navbar-nav" className="justify-content-end">
          <Nav className="mr-3">
            <div class="hexagon-item">
              <a
                href="https://www.cyberblitz.it/how-it-works"
                target="_blank"
                class="hex-content"
              >
                <span class="hex-content-inner">
                  <LinkContainer to="/how-it-works">
                    <Nav.Link active={false}>
                      <span class="icon">
                        <i className={`fas fa-cog`} />
                      </span>
                      <span className="title">How</span>
                    </Nav.Link>
                  </LinkContainer>
                </span>
              </a>
            </div>

            <div class="hexagon-item">
              <a
                href="https://www.cyberblitz.it/plans"
                target="_blank"
                class="hex-content"
              >
                <span class="hex-content-inner">
                  <LinkContainer to="/plans">
                    <Nav.Link active={false}>
                      <span class="icon">
                        <i className={`fa fa-braille`} />
                      </span>
                      <span className="title">Plans</span>
                    </Nav.Link>
                  </LinkContainer>
                </span>
              </a>
            </div>

            <div class="hexagon-item">
              <a
                href="https://www.cyberblitz.it/about"
                target="_blank"
                class="hex-content"
              >
                <span class="hex-content-inner">
                  <LinkContainer to="/about">
                    <Nav.Link active={false}>
                      <span class="icon">
                        <i className={`fa fa-info`} />
                      </span>
                      <span className="title">About</span>
                    </Nav.Link>
                  </LinkContainer>
                </span>
              </a>
            </div>

            <div class="hexagon-item">
              <a
                href="https://www.cyberblitz.it/resources"
                target="_blank"
                class="hex-content"
              >
                <span class="hex-content-inner">
                  <LinkContainer to="/resources">
                    <Nav.Link active={false}>
                      <span class="icon">
                        <i className={`fa fa-bookmark`} />
                      </span>
                      <span className="title">Resources</span>
                    </Nav.Link>
                  </LinkContainer>
                </span>
              </a>
            </div>

            <div class="hexagon-item">
              <a
                href="https://www.cyberblitz.it/blog"
                target="_blank"
                class="hex-content"
              >
                <span class="hex-content-inner">
                  <LinkContainer to="/blog">
                    <Nav.Link active={false}>
                      <span class="icon">
                        <i className={`fa fa-book-open`} />
                      </span>
                      <span className="title">Blog</span>
                    </Nav.Link>
                  </LinkContainer>
                </span>
              </a>
            </div>

            {auth.user && (
              <div class="hexagon-item">
                <a
                  href="https://www.cyberblitz.it/product/dashboard"
                  target="_blank"
                  class="hex-content"
                >
                  <span class="hex-content-inner">
                    <LinkContainer to="/product/dashboard">
                      <Nav.Link active={false}>
                        <span class="icon">
                          <i className={`fa fa-arrows-alt`} />
                        </span>
                        <span className="title">Cyberblitz</span>
                      </Nav.Link>
                    </LinkContainer>
                  </span>
                </a>
              </div>
            )}

            {!auth.user && (
              <div class="hexagon-item">
                <a
                  href="https://www.cyberblitz.it/auth/signin"
                  target="_blank"
                  class="hex-content"
                >
                  <span class="hex-content-inner">
                    <LinkContainer to="/auth/signin">
                      <Nav.Link active={false}>
                        <span class="icon">
                          <i className={`fas fa-sign-in-alt`} />
                        </span>
                        <span className="title">Login</span>
                      </Nav.Link>
                    </LinkContainer>
                  </span>
                </a>
              </div>
            )}

            <div className="vertical">
            {auth.user && (
              <NavDropdown id="dropdown" title="Account">
                <LinkContainer to="/dashboard">
                  <NavDropdown.Item active={false}>
                    Dashboard
                  </NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/contact">
                  <NavDropdown.Item active={false}>
                    Contact
                  </NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/settings/general">
                  <NavDropdown.Item active={false}>
                    Settings
                  </NavDropdown.Item>
                </LinkContainer>

                <Dropdown.Divider />

                <LinkContainer to="/auth/signout">
                  <NavDropdown.Item
                    active={false}
                    onClick={(e) => {
                      e.preventDefault();
                      auth.signout();
                    }}
                  >
                    Sign out
                  </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )}
            </div>
          </Nav>

          {!auth.user && (
            <LinkContainer to="/auth/signup">
              <Button variant="primary">Sign Up</Button>
            </LinkContainer>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarCustom;
