import React, { useState } from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import "./FaqSection.scss";

function FaqSection(props) {
  // Object to store expanded state for all items
  const [expanded, setExpanded] = useState({});
  // Set an item's expanded state
  const setExpandedItem = (index, isExpanded) => {
    setExpanded({
      ...expanded,
      [index]: isExpanded,
    });
  };

  const items = [
    {
      question: "How do I pay?",
      answer:
        "Since we area small micro SaaS Company, we don't have the resources for traditional contracting, invoices, and accounts receivable, instead users pay monthly with a credit card.",
    },
    {
      question:
        "Can I cancel my subscription?",
      answer:
        "Yes, without notice and any time you want. Simply log into your account, go to Billing and cancel. Once the current billing cycle ends, you won't charged.",
    },
    {
      question: "Can I upgrade/downgrade my subscription?",
      answer:
        "Absolutely. Many large organizations have own many companies, have several divisions, ownership interests, or partnerships that require them to assess aspects of their security controls. You can upgrade or downgrade to any level of Cyberblitz.it account.",
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />

        {items.map((item, index) => (
          <article
            className="FaqSection__faq-item py-4"
            onClick={() => {
              setExpandedItem(index, !expanded[index]);
            }}
            key={index}
          >
            <h4>
              <span className="text-primary mr-3">
                <i
                  className={
                    "fas" +
                    (expanded[index] ? " fa-minus" : "") +
                    (!expanded[index] ? " fa-plus" : "")
                  }
                />
              </span>
              {item.question}
            </h4>

            {expanded[index] && <div className="mt-3">{item.answer}</div>}
          </article>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
