import React from "react";
import HeroSection6 from "./../components/HeroSection6";
import ContactSection from "./../components/ContactSection";
import { requireAuth } from "./../util/auth.js";

function ContactPage(props) {
  return (
    <>
      <HeroSection6
        bg="white"
        textColor="dark"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="Contact Us Anytime"
        subtitle=""
      />
      <ContactSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Send Us an Email"
        subtitle="Use this form to send us a message."
        buttonText="Send message"
        buttonColor="primary"
        showNameField={true}
      />
    </>
  );
}

export default requireAuth(ContactPage);
