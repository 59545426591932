import React from "react";
import "./AspectRatio2.scss";

function AspectRatio2(props) {
  return (
    <div
      className="AspectRatio2"
      style={{
        paddingBottom: (1 / props.ratio) * 100 + "%",
      }}
    >
      <div className="AspectRatio2__inner">{props.children}</div>
    </div>
  );
}

export default AspectRatio2;
