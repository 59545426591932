import React from "react";
import Section2 from "./Section2";
import Container from "react-bootstrap/Container";
import SectionHeader3 from "./SectionHeader3";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { LinkContainer } from "react-router-bootstrap";
import AspectRatio2 from "./AspectRatio2";

function ContentCardsSection2(props) {
  const items = [
    {
      image:
        "https://consumeraffinity.com/wp-content/uploads/2021/02/cloud-networks.jpg",
      title: "Cyberblitz.it: An affordable way to manage and track IT risk",
      body: "",
      url: "",
    },
  ];

  return (
    <Section2
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader3
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <Row className="justify-content-center">
          {items.map((item, index) => (
            <Col xs={12} md={6} lg={10} className="py-3" key={index}>
              <LinkContainer to={item.url}>
                <Card as="a" text="dark" className="text-decoration-none">
                  <AspectRatio2 ratio={1 / 0.75}>
                    <Card.Img src={item.image} alt={item.title} variant="top" />
                  </AspectRatio2>
                  <Card.Body>
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text>{item.body}</Card.Text>
                    <div>
                      Cyberblitz provides an automated way of enumerating,
                      tracking, and analyzing IT based cyber risks. Cyberblitz
                      leverages a combination of open-source intelligence tools
                      (OSINT) and closed source data repositories to obtain
                      information about your online footprint. We identify IT
                      assets that are accessible via the public Internet so you
                      can assign data classifications according to the kind of
                      information they contain or can access. This gives you the
                      ability to not just identify a particular asset but what
                      part of the business it supports (eg, marketing,
                      e-commerce, finance) and more importantly, what kind of
                      data and the level of risk associated with it. That could
                      mean consumer data like names and addresses, credit card
                      information, social security numbers, health information,
                      or other personally identifiable information (PII).
                      <div>
                        <br />
                        Once you've signed up, you'll enter a company name in
                        the search box. After hitting the enter key, Cyberblitz
                        will enumerate basic information about the company and
                        present it to you for confirmation. You'll need to add
                        the company to your Cyberblitz account before moving
                        onto the next stage: 'discovery'. Depending on the type
                        of Cyberblitz account you've selected, you'll be limited
                        to a specific number of companies that you can add and
                        track. We use this model to support a wide range of
                        businesses including consulting firms and companies with
                        parent corporate/subsidiary, and ownership interests in
                        other companies and foreign entities.
                      </div>
                      <div>
                        <br />
                        Our technology will obtain information that ranges from
                        registered domains and netblocks, domain name records,
                        and down to the individual host level via ports and
                        banners. Once we collect all of this information, we
                        apply some basic intelligence to it in order to analyze
                        it for common threats. We then provide observations and
                        recommendations to help you reduce your risk. We do not
                        currently perform any port scans, vulnerability
                        assessments, or otherwise attempt to exploit any device
                        we identify. Our port and banner information comes from
                        publicly available repositories that anyone can access.
                        This does include people that might want to do you or
                        your business harm.
                      </div>
                      <div>
                        <br />
                        We eliminate technical jargon and provide sensible
                        recommendations in plain language that anyone can
                        understand. Where necessary, we define terms and explain
                        technical details so that you don't just understand what
                        is being identified as a risk, but why. Our
                        recommendations are, at the moment, tactical in nature.
                        Meaning that we will tell you exactly what to do in
                        order to de-risk the observation that we've identified.
                        In some cases that may mean a fairly simple fix (deploy
                        SSL certificates, disable FTP) or something considerably
                        more difficult but valuable (upgrade the version of the
                        operating system or application identified). We try to
                        avoid "pie in the sky" recommendations that are easy to
                        recommend but extremely difficult and costly to address.
                        That being said, we don't know our customer's
                        information technology environments and so what could be
                        trivial for one customer may wind up being expensive for
                        another. So, unfortunately, we can't guarantee that
                        there won't be some recommendations that fit that exact
                        nature: costly, time-consuming, difficult, and/or
                        requires internal political capital in order to
                        accomplish. We do believe that even if this is the case,
                        projects centered around information security
                        improvements are important and will pay dividends in the
                        long run from reduced risk of compromise or data
                        disclosure.
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </LinkContainer>
            </Col>
          ))}
        </Row>
      </Container>
    </Section2>
  );
}

export default ContentCardsSection2;
