import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import DashSection from "./DashSection";
import DashSection2 from "./DashSection2";
import DashSection3 from "./DashSection3";
import "./ProductSection.scss";

function ProductSection(props) {
  const items = [{}];

  return (
    <div>
      <Section
        bg={props.bg}
        textColor={props.textColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container>
          <Row className="align-items-center">
            <Container>
              <Col lg={15} className="text-center text-lg-left">
                <div className="ProductSection__main">
                  <Tabs defaultActiveKey="Dashboard">
                    <Tab eventKey="Dashboard" title="Dashboard">
                      <div className="ProductSection__dashboard-selector">
                        <br />
                        <br />
                      </div>
                      <div>
                        <Container>
                          <Col>
                            <br />
                            <h2>Welcome!</h2>
                            <Row>
                              <Col>
                                <br />
                                <div className="ProductSection__dash-body">
                                  <h3>Your Dashboard</h3>
                                  <h6>
                                    Technology Overview Across all Companies
                                  </h6>
                                  <DashSection
                                    bg="gray"
                                    textColor="dark"
                                    size="md"
                                    bgImage=""
                                    bgImageOpacity={1}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Container>
                      </div>
                    </Tab>
                    <Tab eventKey="company-profile" title="Company Profile">
                      <div className="ProductSection__company-selector">
                        Use the Company selector to choose a Company you've
                        searched for previously.
                        <section>
                          <br />
                          <select>
                            <option>Company name</option>
                          </select>
                        </section>
                      </div>
                      <Container>
                        <div>
                          <Row>
                            <Col>
                              <br />
                              <h2>Overview</h2>
                              Cyberblitz.it's database has found your
                              organization.
                            </Col>
                          </Row>
                        </div>
                        <Container>
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__company-profile">
                                <h3>Company Profile</h3>
                                <h6>
                                  Company Profile: legal name, industries,
                                  founded date.
                                </h6>
                              </div>
                              <br />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__physical-presence">
                                <h3>Physical Presence</h3>
                                <h6>
                                  Physical Presence: locations, headquarters,
                                  divisions, subsidiaries.
                                </h6>
                              </div>
                              <br />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__employee-information">
                                <h3>Employee Information</h3>
                                <h6>
                                  Number of employees, founders, executive team
                                  members.
                                </h6>
                              </div>
                              <br />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__financial-information">
                                <h3>Financial Information</h3>
                                <h6>
                                  Number of acquisitions, funding amount, number
                                  of investors.
                                </h6>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Container>
                    </Tab>
                    <Tab eventKey="oberservations" title="Observations">
                      <div className="ProductSection__tab-observations">
                        Use the Company selector to choose a Company you've
                        searched for previously.
                        <section>
                          <br />
                          <select>
                            <option>Company name</option>
                          </select>
                        </section>
                      </div>
                      <Container>
                        <Col>
                          <br />
                          <h2>Summary</h2>
                          Cyberblitz.it has made some observations and
                          recommendations based on information gathered.
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__dangerous-software">
                                <h3>Dangerous Software</h3>
                                <h6>Observation: databases on the Internet.</h6>
                                <h6>
                                  Recommendation: protect databases by placing
                                  them behind a firewall.
                                </h6>
                              </div>
                              <br />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__unencrypted-services">
                                <h3>Unencrypted Services</h3>
                                <h6>
                                  Observation: services that transmit data in
                                  clear text like FTP and Telnet.
                                </h6>
                                <h6>
                                  Recommendation: replace with services that
                                  only transmit using encryption like SSH, FTPS,
                                  or SFTP.
                                </h6>
                              </div>
                              <br />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__http">
                                <h3>HTTP instead of HTTPS</h3>
                                <h6>
                                  Observation: sites that are allowing
                                  unencrypted web traffic.
                                </h6>
                                <h6>
                                  Recommendation: protect and encrypt web
                                  traffic with SSL.
                                </h6>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__unnecessary-services">
                                <h3>Unnecessary Services</h3>
                                <h6>
                                  Observation: services that don't necessarily
                                  serve a business function are running on these
                                  servers.
                                </h6>
                                <h6>
                                  Recommendation: reduce your threat profile by
                                  eliminating unnecessary services.
                                </h6>
                              </div>
                              <br />
                            </Col>
                          </Row>
                        </Col>
                      </Container>
                    </Tab>
                    <Tab eventKey="footprint" title="Footprint">
                      <div className="ProductSection__tab-footprint">
                        Use the Company selector to choose a Company you've
                        searched for previously.
                        <section>
                          <br />
                          <select>
                            <option>Company name</option>
                          </select>
                        </section>
                      </div>
                      <Container>
                        <Col>
                          <br />
                          <h2>IT Footprint</h2>
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__footprint-body">
                                <h3>Summary</h3>
                                <h6>Statistics and visuals.</h6>
                                <DashSection2
                                  bg="gray"
                                  textColor="dark"
                                  size="md"
                                  bgImage=""
                                  bgImageOpacity={1}
                                />
                              </div>
                              <br />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__name-resolution">
                                <h3>Name Resolution</h3>
                                <h6>Entries for various DNS resolution.</h6>
                              </div>
                              <br />
                            </Col>
                          </Row>
                          Cyberblitz.it has found domains and netblocks
                          registered to your organization.
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__domains">
                                <h3>Domains</h3>
                                <h6>
                                  Domain names, registered date, expiring date,
                                  person that registered the domain.
                                </h6>
                              </div>
                              <br />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__netblocks">
                                <h3>Netblocks</h3>
                                <h6>Names and ranges.</h6>
                              </div>
                              <br />
                            </Col>
                          </Row>
                        </Col>
                      </Container>
                    </Tab>
                    <Tab eventKey="assets" title="Assets">
                      <div className="ProductSection__tab-assets">
                        Use the Company selector to choose a Company you've
                        searched for previously.
                        <section>
                          <br />
                          <select>
                            <option>Company name</option>
                          </select>
                        </section>
                      </div>
                      <Container>
                        <Col>
                          <br />
                          <h2>IT Assets</h2>
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__assets-dash">
                                <h3>Summary</h3>
                                <h6>Statistics and visuals.</h6>
                                <DashSection3
                                  bg="gray"
                                  textColor="dark"
                                  size="md"
                                  bgImage=""
                                  bgImageOpacity={1}
                                />
                              </div>
                              <br />
                            </Col>
                          </Row>
                          Cyberblitz.it has found devices for your organization.
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__http-servers">
                                <h3>Web (HTTP) Servers</h3>
                                <h6>
                                  Web servers on all ports: 80, 443, 8080 and
                                  more.
                                </h6>
                              </div>
                              <br />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__database-servers">
                                <h3>Database Servers</h3>
                                <h6>
                                  Servers running different database services
                                  (MSSQL, MySQL, Oracle, etc).
                                </h6>
                              </div>
                              <br />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__ftp-servers">
                                <h3>FTP Servers</h3>
                                <h6>
                                  Servers running the File Transfer Protocol
                                  service (Port 21).
                                </h6>
                              </div>
                              <br />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__nameservers">
                                <h3>Nameservers</h3>
                                <h6>
                                  Servers running Domain Name Services (Port
                                  53).
                                </h6>
                              </div>
                              <br />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__smtp-servers">
                                <h3>SMTP (Email) Servers</h3>
                                <h6>
                                  Servers running different email services
                                  (SMTP, POP3, etc).
                                </h6>
                              </div>
                              <br />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__telnet-servers">
                                <h3>Telnet Servers</h3>
                                <h6>Servers running Telnet (Port 23).</h6>
                              </div>
                              <br />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__video-conferencing">
                                <h3>Video Conferencing Servers</h3>
                                <h6>
                                  Servers running Video Conferencing software.
                                </h6>
                              </div>
                              <br />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <br />
                              <div className="ProductSection__all-servers">
                                <h3>All other Servers</h3>
                                <h6>Servers running other services.</h6>
                              </div>
                              <br />
                            </Col>
                          </Row>
                        </Col>
                      </Container>
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Container>
          </Row>
        </Container>
      </Section>
    </div>
  );
}

export default ProductSection;
